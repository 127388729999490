@import '@chialab/cells/lib/colors.css';
@import '@chialab/cells/lib/outline.css';
@import '@chialab/cells/lib/shadows.css';
@import '@chialab/cells/lib/spacing.css';
@import '@chialab/cells/lib/typography.css';

:root {
    --padding: 4px;
    --margin: 4px;
    --gap: var(--spacing, 4px);
    --column: 176px;
    --radius: 2px;
    --scrollbar-size: var(--spacing, 4px);

    --light-0: #fff;
    --grey-100: #f3f3f3;
    --grey-200: #e3e3e3;
    --grey-300: #c3c3c3;
    --grey-500: #888888;
    --grey-700: #495057;
    --grey-800: #333333;
    --grey-900: #212529;

    --color-success: #3e743e;
    --color-warning: #ffd900;
    --color-fail: #b10000;

    --font-sans: 'IBM Plex Sans', sans-serif;
    --font-serif: 'IBM Plex Serif', serif;
    --font-mono: 'IBM Plex Mono', monospace;

    --block: 120px;
}
