@import '../variables.css';

html, body {
    width: 100%;
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    background-color: #212529;
    background-image: linear-gradient(to bottom right, #6c757d 0%, #000 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.shell {
    justify-content: flex-start;
    align-items: flex-start;
    grid-auto-rows: min-content;
    width: calc(var(--column) * 2 + var(--gap) * 3);
    grid-template-columns: var(--column);
    margin: var(--column) auto;
}

.main {
    width: 100%;
    padding: 0;
    grid-row: span 2;
}

.content {
    width: 100%;
}

.login-options button,
.login-options a {
    width: 100%;
}

.aside {
    position: static;
    height: auto;
}

.footer {
    position: static;
    height: auto;
}

.form-group {
    width: 100%;
}
